import {
  Card,
  ListControls,
  ListControlsProps,
  TagButton,
  CountryPickerProps,
  CountryPicker,
} from '@resal-frontend/ui/index';
import { GetServerSideProps } from 'next';
import Image from 'next/image';
import React, { useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useInfiniteQuery, useQuery } from 'react-query';
import { dehydrate } from 'react-query/hydration';
import { useTokenStore } from '@stores';
import { categoriesApi, channelCardsApi, marketsApi } from '../utils/api';
import Layout from '../components/layout/layout';
import messages from '../utils/messages';
import * as Types from '@client/types';
import { MarketStore } from '../utils/market-store';
type sortByKeys = 'priceUp' | 'priceDown' | 'newUp' | 'newDown';
type landingPageVariables = {
  phrase?: string;
  sortBy?: Types.Maybe<Types.Scalars['String']>;
  category?: number | null;
  limit?: number;
  acceptLanguage?: string;
};
const sortKeys: Array<sortByKeys> = [
  'priceUp',
  'priceDown',
  'newUp',
  'newDown',
];

const PAGE_SIZE = 12;

const fetchCards =
  (
    categoryId: number | null | undefined,
    marketId?: number | null,
    phrase?: string | undefined,
    sort?: string | undefined
  ) =>
  async ({ pageParam = 1 }) => {
    const result = await channelCardsApi.listChannelCardsChannelCardsGet({
      perPage: PAGE_SIZE,
      page: pageParam,
      isActive: true,
      categoryId: categoryId ? Number(categoryId) : undefined,
      countryId: marketId ?? 2,
      search: phrase,
      sort: sort,
    });
    return result;
  };

export function Index(props: { variables: landingPageVariables }) {
  const { formatMessage, locale } = useIntl();
  const { loggedIn } = useTokenStore();
  const { market, setMarket } = MarketStore();
  const [phrase, setPhrase] = useState(props.variables.phrase || '');
  const [category, setCategory] = useState<number | undefined>(
    props.variables.category ? Number(props.variables.category) : undefined
  );
  const [sortKey, setSortKey] = useState<sortByKeys | null>(
    (props.variables.sortBy as sortByKeys) || null
  );
  const {
    data,
    error,
    status,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['Cards', category, market, phrase, sortKey],
    fetchCards(category, market, phrase, sortKey?.toString()),
    {
      enabled: loggedIn(),
      keepPreviousData: true,
      staleTime: 50000,
      getNextPageParam(last) {
        return last.meta.currentPage < last.meta.lastPage
          ? last.meta.currentPage + 1
          : false;
      },
      notifyOnChangeProps: [
        'data',
        'hasNextPage',
        'isFetchingNextPage',
        'isLoading',
      ],
    }
  );
  const { data: categoriesRes } = useQuery(
    'getCategories',
    async () =>
      await categoriesApi.listCategoriesCategoriesGet({
        acceptLanguage: locale,
      }),
    {
      enabled: loggedIn(),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
  const { data: marketsData } = useQuery(
    'getMarkets',
    async () =>
      await marketsApi.listMarketsMarketsGet({ acceptLanguage: locale }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const marketItems = useMemo<CountryPickerProps['items']>(
    () =>
      marketsData?.markets?.map((m) => ({
        id: m.id,
        title: locale.indexOf('ar') === 0 ? m.nameAr : m.nameEn,
        flag: `/${m.flag.toLocaleLowerCase().replace('.png', '.svg')}`,
      })) ?? [],
    [marketsData?.markets, locale]
  );

  const sortItems = useMemo<ListControlsProps['sortItems']>(
    () =>
      sortKeys.map((id) => ({
        id: id,
        message: formatMessage(messages[id]),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  );
  const cards =
    data?.pages?.flatMap((page) => (page.data ? page.data : [])) ?? [];
  const scrollRef = useRef<HTMLDivElement | null>(null);
  return (
    <Layout>
      <h1 className="sr-only">{formatMessage(messages.header)}</h1>
      <div className="mt-10 flex flex-wrap">
        <CountryPicker
          currentCountry={
            // 2 is saudi arabia country code
            marketItems?.find((c) => c.id === (market ?? 2))?.title
          }
          items={marketItems}
          onChange={(id) => {
            setMarket(id);
          }}
        />
        <div className="w-5 flex justify-items-center mis-2 md:mis-6">
          <button
            onClick={() => {
              scrollRef.current?.scrollBy({
                behavior: 'smooth',
                left: (locale === 'en' ? -1 : 1) * 220,
              });
            }}
            className=""
          >
            <span className="sr-only">right</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={locale === 'en' ? 'M15 19l-7-7 7-7' : 'M9 5l7 7-7 7'}
              />
            </svg>
          </button>
        </div>
        <div
          className="flex-1 flex overflow-x-auto overflow-y-hidden space-i-3 hide-scrollbar mx-2 relative"
          ref={scrollRef}
        >
          {categoriesRes?.categories.map((cat) => (
            <div
              className="flex-shrink-0 h-full text-xs text-rgray border border-identity-tertiary rounded-md"
              key={cat.id}
            >
              <button
                className="p-3"
                onClick={() => {
                  setCategory(cat.id);
                  if (phrase !== '') {
                    setPhrase('');
                  }
                }}
              >
                {locale == 'ar' ? cat.nameAr : cat.nameEn}
              </button>
            </div>
          ))}
        </div>
        <div className="w-5 flex justify-items-center">
          <button
            onClick={() => {
              scrollRef.current?.scrollBy({
                behavior: 'smooth',
                left: (locale === 'en' ? -1 : 1) * -220,
              });
            }}
            className=""
          >
            <span className="sr-only">left</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d={locale === 'en' ? 'M9 5l7 7-7 7' : 'M15 19l-7-7 7-7'}
              />
            </svg>
          </button>
        </div>
        <div className="md:hidden" style={{ flexBasis: '100%' }} />
        <div className="mis-auto md:mis-2 mt-2 md:mt-0">
          <ListControls
            sortTitle={formatMessage(messages.cardSort)}
            searchTitle={formatMessage(messages.cardSearch)}
            defaultSearchValue={phrase}
            onSearchChange={setPhrase}
            sortItems={sortItems}
            // @ts-expect-error string and const are not playing well
            onSortChange={setSortKey}
          />
        </div>
      </div>
      <hr className="bg-gray-300 mt-5" />
      <div className="mb-7 space-i-3">
        {sortKey !== null ? (
          <TagButton
            className="mt-7"
            title={formatMessage(messages[sortKey])}
            onClose={() => setSortKey(null)}
          />
        ) : null}
        {category != null ? (
          <span className="mt-7 inline-flex rounded items-center py-2 pis-2.5 pie-1 text-sm font-regular bg-identity-tertiary text-identity-tertiary-inverted ring-1 ring-identity-primary">
            {locale == 'ar'
              ? categoriesRes?.categories.find((cat) => cat.id === category)
                  ?.nameAr
              : categoriesRes?.categories.find((cat) => cat.id === category)
                  ?.nameEn}
            <button
              type="button"
              className="flex-shrink-0 mis-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-identity-primary focus:outline-none"
              onClick={() => setCategory(undefined)}
            >
              <span className="sr-only">
                Remove{' '}
                {locale == 'ar'
                  ? categoriesRes?.categories.find((cat) => cat.id === category)
                      ?.nameAr
                  : categoriesRes?.categories.find((cat) => cat.id === category)
                      ?.nameEn}{' '}
                category
              </span>
              <svg
                className="h-2 w-2"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 8 8"
              >
                <path
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  d="M1 1l6 6m0-6L1 7"
                />
              </svg>
            </button>
          </span>
        ) : null}
      </div>
      <div
        className="grid grid-rows-2 grid-cols-2 md:grid-cols-4 gap-5 md:gap-8"
        style={{ minHeight: '400px' }}
      >
        {cards.map((channelCard) =>
          channelCard ? (
            <Card
              key={channelCard.id}
              img={channelCard.image.replace(/\{|\}/gi, '')}
              title={
                locale == 'ar'
                  ? channelCard.card.titleAr
                  : channelCard.card.titleEn
              }
              priceRange={formatMessage(messages.cardStartFrom, {
                start: channelCard.beginPrice,
                end: channelCard.beginPrice,
              })}
              link={`/card/${channelCard.id}`}
            />
          ) : null
        )}
        {isLoading ? <Card placeholderMode /> : null}
        {!isLoading && (phrase !== '' || !category) && cards.length < 1 ? (
          <div className="col-span-2 md:col-span-4 flex flex-col place-content-center text-center text-lg leading-10">
            <p>{formatMessage(messages.noResults)}</p>
          </div>
        ) : (
          ''
        )}
        {!isLoading && hasNextPage ? (
          <div className="col-span-2 md:col-span-4 flex justify-center items-center">
            <button
              className="w-full max-w-xl px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-60"
              disabled={isLoading || isFetchingNextPage}
              onClick={() => {
                fetchNextPage();
              }}
            >
              {isFetchingNextPage ? (
                <div className="flex mx-auto w-24">
                  <Image
                    unoptimized
                    src="/loader.svg"
                    width="200"
                    height="50"
                    className="w-8"
                    layout="intrinsic"
                  />
                </div>
              ) : (
                formatMessage(messages.loadMore)
              )}
            </button>
          </div>
        ) : null}
      </div>
    </Layout>
  );
}

export const variables: landingPageVariables = {
  phrase: '',
  sortBy: null,
  category: null,
  limit: PAGE_SIZE,
  acceptLanguage: 'ar',
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { locale, req } = ctx;
  let loggedIn: boolean;
  if (
    req.cookies?.Authorization &&
    req.cookies?.Authorization.match(/"contextJwt":\s*"(.*)"/)
  )
    loggedIn = true;
  else loggedIn = false;

  const t = Date.now();
  return {
    props: {
      variables: { ...variables, acceptLanguage: locale },
    },
  };
};
export default Index;
