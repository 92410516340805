import create from 'zustand';
import { combine } from 'zustand/middleware';

const getDefault = (): { market: number | null } => ({
  market: null,
});

export const MarketStore = create(
  combine(getDefault(), (set, get) => ({
    setMarket: (
      market: number | null | ((curMarket: number | null) => number | null)
    ) => {
      if (typeof market === 'function') {
        set({
          market: market(get().market),
        });
      } else {
        set({
          market,
        });
      }
    },
  }))
);
