import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'landing.header',
    defaultMessage: 'Gift Cards',
  },
  specialCards: {
    id: 'landing.special',
    defaultMessage: 'Resal Special Cards',
  },
  cardPriceRange: {
    id: 'card.priceRange',
    defaultMessage: 'starting from {start} SAR - {end} SAR',
  },
  cardStartFrom: {
    id: 'card.StartFrom',
    defaultMessage: 'starting from {start} SAR',
  },
  cardSort: {
    id: 'landing.sort',
    defaultMessage: 'Sort',
  },
  cardSearch: {
    id: 'landing.search',
    defaultMessage: 'Search',
  },
  priceUp: {
    id: 'landing.priceUp',
    defaultMessage: 'Expensive first',
  },
  priceDown: {
    id: 'landing.priceDown',
    defaultMessage: 'Expensive last',
  },
  newUp: {
    id: 'landing.newUp',
    defaultMessage: 'Newest',
  },
  newDown: {
    id: 'landing.newDown',
    defaultMessage: 'Oldest',
  },
  noResults: {
    id: 'landing.noResults',
    defaultMessage: ' No cards to show',
  },
  noResultsBrowse: {
    id: 'landing.noResultsBrowse',
    defaultMessage: 'Browse our collection of Gift cards',
  },
  createPassword: {
    id: 'landing.createPassword',
    defaultMessage: 'Create a new password',
  },
  payment: {
    id: 'cart.payment',
    defaultMessage: 'Payment',
  },
  userGuide: {
    id: 'order.payment',
    defaultMessage: 'User Guide',
  },
  copyCode: {
    id: 'order.copyCode',
    defaultMessage: 'Copy code',
  },
  secret: {
    id: 'order.secret',
    defaultMessage: 'Secret code',
  },
  sendAsGift: {
    id: 'order.sendAsGift',
    defaultMessage: 'Send as Gift',
  },
  ccInvlaidMonth: {
    id: 'profile.ccInvlaidMonth',
    defaultMessage: 'Invalid month',
  },
  ccInvlaidYear: {
    id: 'profile.ccInvlaidYear',
    defaultMessage: 'Invalid year',
  },
  ccInvlaidNumber: {
    id: 'profile.ccInvlaidNumber',
    defaultMessage: 'Invalid number',
  },
  ccInvlaidCardHolder: {
    id: 'profile.ccInvlaidCardHolder',
    defaultMessage: 'Invalid Card Holder name',
  },
  ccInvlaidCardCVV: {
    id: 'profile.ccInvlaidCardCVV',
    defaultMessage: 'Invalid verification code',
  },
  pin: {
    id: 'order.pin',
    defaultMessage: 'PIN',
  },
  printGift: {
    id: 'order.printGift',
    defaultMessage: 'PRINT',
  },
  verifyCode: {
    id: 'order.verifyCode',
    defaultMessage: 'Code',
  },
  otpInstructions: {
    id: 'order.otpInstructions',
    defaultMessage:
      'To continue completing the purchase, use the OTP code and confirm your phone number. We sent OTP code to',
  },
  loadMore: {
    id: 'global.loadMore',
    defaultMessage: 'Load more',
  },
  buyCard: {
    id: 'global.buyCard',
    defaultMessage: 'Buy card',
  },
  addToCart: {
    id: 'global.addToCart',
    defaultMessage: 'Add To Cart',
  },
  sendNow: {
    id: 'order.sendNow',
    defaultMessage: 'Gift now',
  },
  speicifyDate: {
    id: 'order.speicifyDate',
    defaultMessage: 'Specify gift date',
  },
  send: {
    id: 'order.send',
    defaultMessage: 'Send',
  },
  preview: {
    id: 'order.preview',
    defaultMessage: 'Preview',
  },
  designGift: {
    id: 'order.designGift',
    defaultMessage: 'Design your E-Gift',
  },
  giftEvent: {
    id: 'order.giftEvent',
    defaultMessage: 'Event Information',
  },
  giftSendInfo: {
    id: 'order.giftSendInfo',
    defaultMessage: 'Sending Information',
  },
  senderName: {
    id: 'order.senderName',
    defaultMessage: 'Sender name',
  },
  recipientName: {
    id: 'order.recepientName',
    defaultMessage: 'Recipient name',
  },
  recipientEmail: {
    id: 'order.recepientEmail',
    defaultMessage: 'Recipient email',
  },
  summary: {
    id: 'cart.summary',
    defaultMessage: 'Order Summary',
  },
  order: {
    id: 'cart.order',
    defaultMessage: 'Order',
  },
  serviceFees: {
    id: 'cart.serviceFees',
    defaultMessage: 'Service Fees',
  },
  vat: {
    id: 'cart.vat',
    defaultMessage: 'VAT {vatRate}',
  },
  subtotal: {
    id: 'cart.subtotal',
    defaultMessage: 'Subtotal',
  },
  totalWithVat: {
    id: 'cart.totalWithVat',
    defaultMessage: 'Total',
  },
  verifyPhone: {
    id: 'cart.verifyPhone',
    defaultMessage: 'Please verify your phone',
  },
  resalWallet: {
    id: 'cart.resalWallet',
    defaultMessage: 'Resal Wallet',
  },
  useResalWallet: {
    id: 'cart.useResalWallet',
    defaultMessage: 'Use Resal Wallet balance',
  },
  chargeAcct: {
    id: 'cart.chargeAcct',
    defaultMessage: 'Charge your account',
  },
  yourBlanace: {
    id: 'cart.yourBlanace',
    defaultMessage: 'Your balance is',
  },
  yourBlanaceCharged: {
    id: 'cart.yourBlanaceCharged',
    defaultMessage: 'Your balance will be charged',
  },
  confirmPayment: {
    id: 'cart.confirmPayment',
    defaultMessage: 'Confirm Payment',
  },
  failedToPlaceOrder: {
    id: 'cart.failedToPlaceOrder',
    defaultMessage: 'Sorry some error happened, please try again later',
  },
  payStcPay: {
    id: 'cart.payStcPay',
    defaultMessage: 'Pay via STCPay',
  },
  stcPayMsg: {
    id: 'cart.stcPayMsg',
    defaultMessage:
      'Enter your mobile number and your STCPay account will be charged',
  },
  aboutCard: {
    id: 'cart.aboutCard',
    defaultMessage: 'About the Card',
  },
  howToUse: {
    id: 'cart.howToUse',
    defaultMessage: 'How to use',
  },
  cashback: {
    id: 'cart.cashback',
    defaultMessage:
      'Pssst!! you can get cashback on your Resal Balance, Just use MasterCard and get 10% cashback NOW',
  },
  maxChar: {
    id: 'order.maxChar',
    defaultMessage: 'The message should be no more that {num} characters',
  },
  close: {
    id: 'global.close',
    defaultMessage: 'Close',
  },
  ok: {
    id: 'global.ok',
    defaultMessage: 'OK',
  },
  itemPrice: {
    id: 'cart.itemPrice',
    defaultMessage: 'Price: {num}',
  },
  siftMessageTitle: {
    id: 'cart.siftTitle',
    defaultMessage: 'Order under review',
  },
  siftMessageBody: {
    id: 'cart.siftBody',
    defaultMessage:
      'Please send a picture of the payment with the amount to cs@resal.me.<br></br>You can also reach us via chat for a faster response',
  },
  paymentSuccessful: {
    id: 'pending.paymentSuccessful',
    defaultMessage: 'Payment Successful',
  },
  orderSent: {
    id: 'pending.orderSent',
    defaultMessage: 'Your order information details was sent to your email',
  },
  ratingTitle: {
    id: 'rating.title',
    defaultMessage: 'Store customer survey',
  },
  ratingThanks: {
    id: 'rating.tanks',
    defaultMessage: 'Thank you for answering the survey',
  },
  ratingSatisfaction: {
    id: 'rating.satisfaction',
    defaultMessage: 'How satisfied are you with your experience?',
  },
  ratingRecommend: {
    id: 'rating.recommend',
    defaultMessage:
      'How likely are you to recommend the store to a friend or colleague?',
  },
  ratingLikely: {
    id: 'rating.likely',
    defaultMessage: 'Extremely Likely',
  },
  ratingUnlikely: {
    id: 'rating.unlikely',
    defaultMessage: 'Extremely Unlikely',
  },
  ratingComment: {
    id: 'rating.comment',
    defaultMessage: 'Leave a comment',
  },
  ratingSubmit: {
    id: 'rating.submit',
    defaultMessage: 'Send survey',
  },
  ratingBrowse: {
    id: 'rating.browse',
    defaultMessage: 'Browse e-gift cards',
  },
  checkQty: {
    id: 'card.checkQty',
    defaultMessage: 'unable to complete order, available quantity',
  },
  checkItemQty: {
    id: 'cart.checkItemQty',
    defaultMessage: 'unable to complete order, available quantity',
  },
});
